.section {
  @include size(100%, auto);
  position: relative;
  margin: 10rem auto;
  padding: 0 $sitePaddingHorizontal;

  @include respond-to(XXLARGE) {
    width: $siteMaxWidth;
  }
}

header + .section {
  margin-top: 0;
}
