$pathAsset: "~src";

// Fonts
$baseFont: 'HelveticaNeue', sans-serif;

// Z-Index
$zLayout: default, header, navigation, modal;

// Colors
$colorWhite: #fff;
$colorLightGray: #BBB;
$colorGray: #181A1B;
$colorBlack: #000;

// Sizes
$headerHeight: 12rem;
$footerHeight: 24rem;
$sitePaddingHorizontal: 5%;
$siteMaxWidth: 144rem;

$easeInOutVinnie: cubic-bezier(0.2, 0, 0, 1);
