@import '../../../style/util';

.header {
  @include size(100%, $headerHeight);
  position: relative;
  display: flex;
  align-items: center;
  max-width: $siteMaxWidth;
  font-size: 1.4rem;
  font-weight: 300;
  margin: 0 auto;
  padding: 0 $sitePaddingHorizontal;
  z-index: 1;
}

.title {
  .home & {
    pointer-events: none;
  }
}

.navigation {
  display: flex;
}

.link {
  position: relative;
  display: block;
  margin: 0 0.5em;

  &:first-of-type {
    margin-left: 1em;
  }

  .label {
    &::before {
      @include pseudo;
      width: 100%;
      bottom: 0.23em;
      border-bottom: 1px solid;
      opacity: 0.5;
      transform: scaleX(0);
      transform-origin: 53%;
      transition: transform 0.15s ease-out;
    }
  }

  &:hover {
    .label {
      &::before {
        transform: scaleX(1);
      }
    }
  }
}
