@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../assets/fonts/HelveticaNeueLTStd-Bd.woff2') format('woff2'),
  url('../../assets/fonts/HelveticaNeueLTStd-Bd.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../assets/fonts/HelveticaNeueLTStd-Md.woff2') format('woff2'),
  url('../../assets/fonts/HelveticaNeueLTStd-Md.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../assets/fonts/HelveticaNeueLTStd-Lt.woff2') format('woff2'),
  url('../../assets/fonts/HelveticaNeueLTStd-Lt.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'HelveticaNeue';
  src: url('../../assets/fonts/HelveticaNeueLTStd-Th.woff2') format('woff2'),
  url('../../assets/fonts/HelveticaNeueLTStd-Th.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
