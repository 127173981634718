@import '../../../style/util';

.photoDetail {
  @include size(100%, 100vh);
  margin-top: -$headerHeight;
  padding: 10px 0;
  max-height: calc(100vw + #{$headerHeight});
}

.copyContainer {
  margin: 2rem 0;
}

.imageContainer {
  position: relative;
  @include size(100%);
}

.img {
  @include size(100%);
  object-fit: contain;

}

.navButton {
  @include size(30%, 100%);
  position: absolute;
  top: 0;

  &:first-of-type{
    cursor: w-resize;
  }

  &:last-of-type {
    right: 0;
    cursor: e-resize;
  }
}
