/* Stylesheet for all generic typography styles */

.heading-01,
.heading-03,
.heading-06 {
  color: $colorGray;
  font-weight: bold;
  font-family: 'HelveticaNeue', serif;
  line-height: 1;
}

.heading-01 {
  font-size: 5rem;
  letter-spacing: -0.015em;
}

.heading-03 {
  font-size: 2.6rem;
  letter-spacing: -0.01em;
}

.heading-06 {
  font-size: 2.0rem;
}

.body {
  font-size: 1.3rem;
  font-weight: 500;
  line-height: 1.25;
  color: $colorGray;
}

.small {
  font-size: 1.0rem;
  font-weight: 500;
  line-height: 1.25;
  color: $colorGray;
}
