@import '../../../style/util';

.about {
  display: flex;
  align-items: center;
  min-height: calc(100vh - #{$headerHeight} - #{$footerHeight});
  margin-bottom: 0;
  padding-top: 10rem;
  padding-bottom: 20rem;
}

.wrapper {
  margin-right: 10%;
}

.copy {
  font-weight: 300;
  line-height: 1.5;
  font-size: 1.4rem;
}

.icon {
  margin-bottom: -0.2em;
}
