.icon {
  display: inline-block;
  pointer-events: none;

  svg {
    display: block;
    height: 100%;
    width: 100%;
  }

  &.fillCurrentColor {
    svg {
      fill: currentColor;
    }
  }
}
