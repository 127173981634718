@import '../../../../style/util';

.container {
  @include size(100%);
  @include offset(0, 0);
  background: rgba(0,0,0,.9);
  display: flex;
  position: fixed;
  z-index: 1;
  align-items: center;
  justify-content: center;
}

.contentWrapper {
  width: calc(100% - 60px);
  height: calc(100vh - 100px);
  position: relative;
  overflow: hidden;
  background-color: $colorBlack;
}

.image {
  position: relative;
}

.bgButton {
  @include size(100%);
  position: absolute;
  top: 0;
  background-color: transparent;
}

.closeButton {
  @include size(40px);
  background-color: transparent;
  color: $colorWhite;
  padding: 0;
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 1;
  transition: transform .25s ease-out;

  span {
    @include size(16px, 15px);
    display: block;
    font-size: 0;
    margin: 0 auto;
  }

  &:hover {
    transform: rotate(90deg);
  }
}
