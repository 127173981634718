@import '../util';

html {
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 10px;
}

body {
  width: 100%;
  background-color: $colorWhite;
  font-family: $baseFont;
  font-feature-settings: 'ss02' on;
}

*,
*:before,
*:after {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: inherit;
}

ul,
ol,
dl,
dd {
  list-style: none;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

p {
  font-weight: 300;
}

figure {
  margin: 0;
}

img {
  display: block;
}

button,
.button {
  cursor: pointer;
}

button {
  border: none;
  background: none;
  padding: 0;
  text-align: left;

  &:active,
  &:focus {
    outline: none;
  }
}

a {
  text-decoration: none;
  color: inherit;
}

a,
input {

  &:focus {
    -webkit-focus-ring-color: transparent;
    outline: none;
  }
}

.noscroll {
  overflow: hidden;
  position: fixed;
}
