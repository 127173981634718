@import '../../../style/util';

.footer {
  @include size(100%, $footerHeight);
  position: relative;
  display: flex;
  justify-content: center;
  background-color: $colorBlack;
  color: white;

  ul {
    display: flex;
    width: 100%;
    padding: 0 $sitePaddingHorizontal;
    font-size: 1.2rem;
    align-items: center;

    li {
      position: relative;
      margin: 0 1rem;

      &:first-child {
        margin-left: 0;
      }

      &:not(:first-child) {

        &::before {
          @include pseudo();
          border-left: 1px solid $colorLightGray;
          height: 1.2rem;
          left: -1rem;
          top: 5px;
        }
      }
    }
  }

  @include respond-to(XXLARGE) {
    ul {
      width: $siteMaxWidth;
    }
  }
}

.iconButton {
  @include size(2rem);
  display: block;

  &:hover {
    svg {
      opacity: 1;
    }
  }

  svg {
    opacity: 0.6;
    fill: $colorWhite;
    transition: opacity ease-out 0.3s;
  }
}
