@import '../../../style/util';

.list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -3rem;

  @include respond-to(MEDIUM) {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}

.item {
  @include aspect-ratio(6, 7);
  position: relative;
  width: 100%;
  max-width: 390px;
  margin: 3rem 0;

  a {
    @include size(100%);
    position: absolute;
    top: 0;

    &:hover {
      .info {
        background-color: rgba(0,0,0,.5);

        .title,
        .description {
          display: block;
        }
      }
    }
  }

  @include respond-to(MEDIUM) {
    width: 45%;
  }

  @include respond-to(LARGE) {
    width: 31.258%;
    // width: 30.777%;
  }
}

.img {
  @include size(100%);
  object-fit: cover;
  object-position: center;
}

.info {
  @include size(100%);
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  color: $colorWhite;
  text-align: center;
  background-color: rgba(0,0,0,0);
  transition: background-color ease-out .3s;

  .title,
  .description {
    display: none;
    color: $colorWhite;
  }

  .description {
    opacity: 0.7;
    margin-top: 0.75rem;
  }
}
